import React from "react";
import logoOutline from "../../img/logo_outline.png";

export default class Nav extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            menuOpened: false
        }
    }

    toggleMenu(){
        this.setState({
            menuOpened: !this.state.menuOpened
        });
    }

    render(){
        return(
            <nav>
                <div className="menu-mobile-toggle-wrapper">
                    <a href="#!" onClick={this.toggleMenu.bind(this)}><i className={this.state.menuOpened ? "fas fa-times fa-3x" : "fas fa-bars fa-3x"}></i></a>
                </div>
                <div className="logo">
                    <a href="#bienvenido"><img src={logoOutline} alt=""/></a>
                </div>
                <ul className={this.state.menuOpened ? "menu active" : "menu"}>
                    <li className="menu-logo"><img src={logoOutline} alt=""/></li>
                    <li className="menu-item"><a href="#bienvenido" className="active" onClick={this.toggleMenu.bind(this)}>Bienvenidos</a></li>
                    <li className="menu-item"><a href="#productos" onClick={this.toggleMenu.bind(this)}>Productos</a></li>
                    <li className="menu-item"><a href="#quienes-somos" onClick={this.toggleMenu.bind(this)}>Quiénes somos</a></li>
                    <li className="menu-item"><a href="#proyectos" onClick={this.toggleMenu.bind(this)}>Proyectos</a></li>
                    <li className="menu-item"><a href="#contacto" onClick={this.toggleMenu.bind(this)}>Contacto</a></li>
                </ul>
            </nav>
        );
    }
}